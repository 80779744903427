.player-table-header {
    display: flex;
    margin-bottom: .5rem;
}

.player-table-header-column {
    flex: 1 0 40%;
}

.player-table-header-title .fieldset label,
.player-table-header-title .fieldset input {
    display: inline-block;
    margin-right: 1rem;
}

.player-table-header-title h3 {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
}

.player-table-header-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.player-table-header-actions .button {
    padding: 0 .5rem;
    border-radius: .5rem;
    text-align: center;
}

.player-table-header-action {
    margin-left: 1rem;
    line-height: 3rem;
}

.player-table-header-action:hover {
    background-color: var(--secondary-light);
    transition: 300ms ease all;
}

.player-table {
    width: 100%;
    border-collapse: collapse;
}

.player-table th {
    border-bottom: 1px solid darkgray;
}

.player-table tr {
    border-bottom: 1px solid darkgray;
}

.player-table td {
    padding: .5rem 0;
}

.player-table td:first-child {
    padding-left: .5rem;
}

.player-table td:last-child {
    padding-right: .5rem;
}

.player-table tbody tr:hover {
    background-color: lightgray;
    cursor: pointer;
}

.player-table .selected {
    background-color: lightslategray;
}

.player-table .action-column {
    display: flex;
}

.add-player-checkbox {
    width: 1.3rem;
    height: 1.3rem;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}