.league-tile-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
    padding: 1rem 0;
}

.league-tile {
    height: 24vh;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 1rem;
    border-radius: .5rem;
}

.league-tile:hover {
    cursor: pointer;
    background-color: var(--secondary-light);
    transition: 300ms ease background-color;
}

.league-tile-name {
    width: 100%;
    margin: 1rem 0;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}

.league-tile main {
    width: 100%;
}

.league-title-info {
    margin: 1rem 0;
}

.league-title-info dt {
    /*font-weight: ;*/
}

.league-tile .picture-wrapper {
    overflow: hidden;
    background-repeat: repeat;
    background-size: 100%;
    width: 100%;
    flex-grow: 1;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}

.league-tile .picture-wrapper img {
    width: 100%;
}