.league-events {
    display: grid;
    grid-template-columns: 25% 75%;
    /*grid-template-columns: 25% 50% 25%;*/
    /*display: flex;*/
    /*flex-direction: row;*/
}

.league-events-left {
    /*flex-grow: 1;*/
    margin-top: 2rem;
    margin-left: 2rem;
}

.league-events-list h3 {
    margin-bottom: 0;
}

.league-events-list ul {
    margin-top: 0;
    margin-left: 1rem;
    font-size: .9rem;
}

.league-events-left h3 {
    text-transform: uppercase;
    font-size: .9rem;
}

.league-event-icon {
    margin-right: .5rem;
}

.league-events-list li {
    margin: 1rem 0;
}

.league-events-main {
    display: flex;
}

.league-events-main .snackbar {
    width: 90%;
    margin-right: auto;
}

.league-events-body {
    flex: 1 0 66%;
}

.league-events-right {
    flex: 1 0 calc(33% - 1rem);
    padding: 1rem;
}

.league-events-body-main {
    padding: 0 2rem 0 0;
    display: flex;
    flex-wrap: wrap;
}

.league-events-body-main .work-pane {
    flex: 1 0 calc(100% - 3rem);
}

.league-events-right .work-pane {
    flex: 1 0 calc(100% - 3rem);
}

.league-events-right .button {
    width: calc(100% - 1rem);
    padding: .7rem 0;
    margin-bottom: 1rem;
}

.league-events-right .transparent-border-button {
    border: none;
    border-radius: .1rem;
}

.league-events-right .transparent-border-button:hover {
    background-color: inherit;
    box-shadow: 0 1px 6px #e4e8eb;
}
