.tooltip-disabled .hover-tooltip {
    display: none;
}

.hover-tooltip-container {
    position: relative;
    display: inline-block;
}

.hover-tooltip-container .hover-tooltip {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

.hover-tooltip-container .hover-tooltip {
    width: 120px;
    top: 100%;
    left: 50%;
    margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

.hover-tooltip-container:hover .hover-tooltip {
    visibility: visible;
}

.hover-tooltip-container .hover-tooltip::after {
    content: " ";
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}