.LeagueList {
    padding: 2rem 0;
}

.league-tile-title {
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.add-league {
    background-color: lightgray;
    cursor: pointer;
}

.LeagueList form {
    width: 25vw;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    padding: 1rem;
}

.LeagueList fieldset {
    border: none;
    top: 0;
    display: flex;
    justify-content: space-between;
}

.LeagueList fieldset.form-actions {
    display: flex;
    justify-content: space-between;
}

.LeagueList form label,
.LeagueList form input,
.LeagueList form button {
    flex-grow: 1;
}

.feed-container {
    max-width: 600px;
    width: 100%;
    margin: 2rem auto;
}

.feed-container-header {
    border-bottom: 1px solid var(--inverse-border);
    padding: .5rem 0;
    font-size: 1.2rem;
}

.feed-container-body .snackbar {
    width: 96%;
    margin: 1rem auto;
}

.feed-container-section {
    margin: 2rem 0;
}

.watch-list-players > main {
    display: flex;
    flex-direction: column;
}

.player-bookmark {
    background-color: var(--border);
    padding: 1rem 0;
    margin: .25rem 0;
    display: flex;
    width: 100%;
}

.player-bookmark a {
    text-decoration: none !important;
    color: var(--text) !important;
}

.player-bookmark:hover {
    background-color: var(--secondary-light);
    transition: 300ms ease background-color;
    cursor: pointer;
}

.player-bookmark-info, .player-bookmark-action {
    flex-grow: 1;
}

.player-bookmark-info {
    margin-left: 1rem;
}

.player-bookmark-name {
    font-size: 1.1rem;
}

.player-bookmark-action {
    text-align: right;
    line-height: 2rem;
    margin-right: 1rem;
}

.recent-match {
    margin: .5rem 0;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.recent-match-players, .recent-match-date {
    flex-grow: 1;
}

.recent-match-date {
    text-align: right;
}

/* ----------- Non-Retina Screens ----------- */
/*@media screen*/
/*and (min-device-width: 801px)*/
/*and (-webkit-min-device-pixel-ratio: 1) {*/
/*    .LeagueList ul {*/
/*        flex-direction: row !important;*/
/*    }*/

/*    .LeagueList li {*/
/*        flex: 1 0 21% !important;*/
/*    }*/

/*    .LeagueList li div {*/
/*        height: 25vh;*/
/*        line-height: 24vh;*/
/*    }*/
/*}*/

/*Small Mobile, <375px*/
@media only screen and (max-device-width: 767px) {
    .user-dashboard .league-tile-container {
        display: flex;
        flex-direction: column;
    }

    .user-dashboard .league-tile {
        margin-right: 1rem;
    }

    .league-tile-name {
        text-align: center !important;
    }

    .feed-container-body {
        margin: 0 1rem;
    }
}
