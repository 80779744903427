.league-player {
    padding: 1rem;
}

.league-router-header .hover-tooltip-container,
.league-router-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.league-router-header button {
    margin: 1rem auto;
}

.league-player .transparent-border-button-link:hover {
    background-color: var(--primary-light);
    transition: 300ms ease background-color;
}

.league-player-stats,
.league-player-match-history {
    width: 100%;
}

.league-player-match-history table {
    border-collapse: collapse;
}

.league-player-match-history thead {
    border-bottom: 1px solid var(--inverse-border);
}

.league-player-match-history tbody tr:hover {
    cursor: pointer;
}

.league-player-match-history tbody tr:hover {
    background-color: var(--primary-light);
    transition: 300ms ease all;
}

/*Small Mobile, <375px*/
@media only screen and (max-device-width: 767px) {
    .league-player-stats {
        margin: 2rem 0;
        border-top: 1px solid var(--inverse-border);
        border-bottom: 1px solid var(--inverse-border);
    }

    .league-player-match-history table tbody tr {
        border-bottom: 1px solid var(--border);
    }

    .league-player-match-history table td {
        padding: 1rem 0;
    }
}

/* Table, Desktop ------*/
@media only screen and (min-device-width: 768px) {

    .league-player-stats,
    .league-player-match-history {
        margin: 1rem;
    }

    .league-player-stats table,
    .league-player-match-history table {
        margin: 0 auto;
    }

    .league-player-match-history td {
        padding: 1rem;
    }
}