.fieldset {
    margin: 1rem 0;
}

.fieldset label,
.fieldset input {
    display: block;
}

.fieldset-info label,
.fieldset-info div {
    display: inline-block;
    padding: .5rem;
    margin: 0 !important;
    border: 1px solid var(--primary);
}

.fieldset-info {
    padding: 0;
    margin: 0;
}

.fieldset-info label {
    background-color: var(--primary);
    color: white !important;
}

.fieldset label {
    margin-bottom: .5rem;
    color: var(--header-text);
}

.fieldset input {
    padding: .3rem;
}

.fieldset-info.primary,
.fieldset-info.primary label {
    background-color: var(--primary);
}

.fieldset-info.secondary,
.fieldset-info.secondary label {
    background-color: var(--secondary);
}

.fieldset-info.inverse,
.fieldset-info.inverse label {
    background-color: var(--inverse-foreground);
}

.fieldset-toggle label,
.fieldset-toggle input {
    display: inline-block;
    margin-right: .5rem;
}

.fieldset-toggle {
    margin: 1rem 0;
}