.app-title-tournament {
    color: var(--secondary);
}

.app-title-arc {
    color: var(--inverse-shadow);
}

.app-title-inverse .app-title-tournament {
    color: var(--secondary-light);
}

.app-title-inverse .app-title-arc {
    color: white;
}