.share-button-container {
    width: 25rem;
    margin: 0 auto;
}

.share-button {
    margin: .25rem;
}

.share-button:hover {
    cursor: pointer;
}

.share-button-copy-container {
    display: flex;
    border: 1px solid var(--inverse-border);
    width: 25rem;
}

.share-button-copy-container.copy-success {
    background-color: lightgreen;
}

.share-button-copy-container textarea {
    flex-grow: 1;
    border: none;
    background-color: inherit;
}

.share-button-copy-container button {
    margin: 0 .5rem;
}