.league-router-header, .league-router-header h2 {
    width: 100%;
    text-align: center;
    padding: .5rem 0;
}

.league-router-nav {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
}

.league-router-nav-item {
    font-family: Catamaran, sans-serif;
    font-weight: 700;
    padding: 1.5rem 1rem;
    margin: 0 1rem;
    text-decoration: none;
    color: var(--header-text);
    text-transform: uppercase;
    letter-spacing: .12em;
    font-size: .8rem;
    position: relative;
}

.league-router-nav-item-active:after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    top: 50px;
    border-width: 0 0 2px;
    border-style: solid;
    border-color: var(--underline);
}

/*Small Mobile, <375px*/
@media only screen and (max-device-width: 767px) {

}

/* Table, Desktop ------*/
@media only screen and (min-device-width: 768px) {

}