.league-permission-settings {
}

.league-permission-settings .permissions-table {
    border-collapse: collapse;
    padding: 0;
    margin: 0;
    width: auto;
    display: block;
    border-spacing: 0;

}

.permissions-table {
    width: 90%;
}

.permissions-table th,
.permissions-table td {
    padding: 1rem 0;
}
