.league-info {
    text-align: center;
}

.league-dashboard-players table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
}

.league-dashboard-players td {
    padding: 1rem;
}

.league-dashboard th {
    text-align: left;
}

.league-dashboard td {
    text-align: left;
    padding-left: 0;
}

.league-dashboard th:last-child,
.league-dashboard td:last-child {
    text-align: right;
    padding-right: 0;
}

.league-dashboard td:first-child {
    padding-left: 0;
}


.league-dashboard-players thead {
    border-bottom: 1px solid var(--inverse-border);
}

.league-dashboard-players tbody tr:hover {
    cursor: pointer;
}

.league-dashboard-players tbody tr:hover {
    background-color: var(--primary-light);
    transition: 300ms ease all;
}

/*Small Mobile, <375px*/
@media only screen and (max-device-width: 767px) {
    .league-dashboard {
        margin: 1rem;
    }

    .league-info address {
        line-height: 2rem;
    }

    .league-dashboard-players tbody tr {
        border-bottom: 1px solid var(--border);
    }
}

/* Table, Desktop ------*/
@media only screen and (min-device-width: 768px) {
    .league-dashboard {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .league-dashboard-players {
        width: 100%;
        padding: 0 2rem;
    }

    .league-dashboard-players-table-filter .fieldset {
        margin: 2rem 0;
    }

    .league-dashboard-players-table-filter,
    .league-dashboard-players-table-wrapper {
        width: 75%;
        max-width: 900px;
        margin: 0 auto;
    }
}
