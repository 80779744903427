.page-header h1,
.page-header h2 {
    padding: .5rem 0;
    margin: 0;
    color: var(--text);
}

.page-header h1 a {
    text-decoration: none;
    color: var(--secondary);
}

.page-header h1 a:hover {
    color: var(--secondary);
}

.page-header {
    z-index: 10;
    border-bottom: 1px solid var(--border);
}

.page-header-login {
    padding: 2rem;
    text-align: center;
}

.header-row {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.page-header-item-left {
    justify-self: start;
}

.page-header-item-center {
    justify-self: center;
}

.app-title, .app-title a {
    width: 100%;
    text-align: center;
}

.page-header-item-right {
    /*justify-self: end;*/
    display: flex;
    justify-content: flex-end;
}

.page-title h2 a {
    text-decoration: none;
    color: var(--text);
}

.page-subheader-item {
    flex-grow: 1;
    padding-left: 1rem;
    padding-bottom: .5rem;
}

.page-body {
    min-height: 100vh;
    background-color: var(--foreground);
    padding-bottom: 5rem;
}

.main-nav {
    text-align: center;
}

.main-nav-item {
    font-family: Catamaran, sans-serif;
    font-weight: 700;
    padding: 1.5rem 1rem;
    margin: 0 1rem;
    text-decoration: none;
    color: var(--header-text);
    text-transform: uppercase;
    letter-spacing: .12em;
    font-size: .8rem;
    position: relative;
}

.main-nav-item-active:after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    top: 50px;
    border-width: 0 0 2px;
    border-style: solid;
    border-color: var(--underline);
}

/* ----------- Non-Retina Screens ----------- */
@media screen
and (min-device-width: 801px)
and (-webkit-min-device-pixel-ratio: 1) {
    .page-header .page-header-item {
        padding: 0 1rem;
    }
}

.anonymous-page-wrapper {
    display: flex;
    flex-direction: column;
}

.anonymous-page-wrapper-header {
    margin: 2rem 0;
}

.anonymous-page-wrapper .page-body {
    width: 100%;
}

.anonymous-page-wrapper .page-body header {
    width: 100%;
    text-align: center;
    padding: 2rem 0;
}

.anonymous-page-wrapper-body {
    text-align: center;
}

@media only screen and (max-device-width: 767px) {
    .page-wrapper .page-header .header-row {
        display: flex;
        flex-direction: row;
    }

    .page-wrapper .page-header-item-left {
        flex-grow: 1;
    }

    .page-wrapper .page-header-item-left h1 {
        text-align: left;
    }

    .page-wrapper .page-header-item-right {
        flex-grow: 1;
        margin: 0 1rem;
    }

    .page-body header {
        margin: 0 1rem;
        text-align: left;
    }
}