.unauthenticated {
    margin: 3rem auto 0;
    text-align: center;
}

.unauthenticated .transparent-border-button-link {
    font-size: 1.1rem;
    padding: 1rem 5rem;
    border-radius: .25rem;
    background-color: var(--secondary);
    color: white;
}

.unauthenticated .transparent-border-button-link:hover {
    background-color: var(--secondary-light);
    transition: background-color ease-in 300ms;
}

.unauth-body {
    background-color: white;
}

@media only screen and (max-device-width: 767px) {
    .unauthenticated .transparent-border-button-link {
        width: 90%;
        padding: 1rem 2rem;
    }
}