.print-view-header {
    width: 100%;
    text-align: center;
    margin: .5rem 0;
}

.league-players-print-view {
    width: 100%;
    display: flex;
}

.league-players-print-view table {
    width: 48%;
    border-collapse: collapse;
    margin-right: 1rem;
}

.league-players-print-view table:last-child {
    border-left: 1px solid darkgrey;
}

.league-players-print-view .checkbox {
    padding-right: .5rem;
}

.league-players-print-view table th {
    border-bottom: 2px solid darkgray;
}

th.empty-header {
    border-bottom: none !important;
}

.league-players-print-view table tr {
    /*border-bottom: 1px solid darkgray;*/
}

.league-players-print-view table td {
    padding: .5rem 0;
}

.league-players-print-view table td:first-child {
    padding-left: 1rem;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}