.snackbar {
    width: 100%;
    margin: 1rem 0;
    display: flex;

}

.snackbar-info,
.snackbar-alert {
    background-color: var(--highlight);
}

.snackbar-question {
    background-color: var(--primary-light);
}

.snackbar-warn {
    background-color: yellow;
}

.snackbar-icon {
    font-size: 2rem;
    padding: 1rem;
    color: var(--primary-dark);
}

.snackbar-question .snackbar-icon {
    color: white;
}

.snackbar-contents {
    flex-grow: 1;
    padding: 1rem;
    width: 100%;
    line-height: 2rem;
}

.snackbar-dismiss {
    flex-grow: 1;
    justify-content: flex-end;
    margin-right: 1rem;
}

.snackbar-dismiss:hover {
    cursor: pointer;
}

.snackbar-question .snackbar-dismiss {
    color: white;
}

.snackbar-question .snackbar-dismiss:hover {
    color: var(--secondary);
}