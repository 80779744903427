.transparent-button {
    background-color: inherit;
    padding: .5rem;
    border: none;
}

.transparent-button:hover {
    background-color: var(--border);
    transition: background-color 100ms;
    /*border-radius: 1rem;*/
    cursor: pointer;
    box-shadow: 0 1px 6px var(--border);
}

.transparent-border-button, .transparent-border-button-link {
    background-color: inherit;
    padding: .5rem;
    border: 1px solid #e4e8eb;
    border-radius: 1rem;
    font-size: .8rem;
}

.transparent-border-button:hover, .transparent-border-button-link:hover {
    background-color: var(--foreground);
    cursor: pointer;
}

.transparent-border-button-link {
    text-decoration: none;
    color: black;
    font-size: .8rem;
}

/*#7a170a*/
.button {
    font-size: .9rem;
    font-weight: bold;
    padding: .7rem 3rem;
    border: none;
    border-radius: .2rem;
    background-color: var(--secondary);
    color: var(--background);
}

.button:hover {
    background-color: var(--secondary-light);
    transition: background-color ease-in 200ms;
    cursor: pointer;
}

.button:disabled {
    background-color: darkgrey;
    color: gray;
    box-shadow: 0 0 0;
    cursor: not-allowed;
}

.button-primary {
    background-color: var(--primary);
}

.button-primary:hover {
    background-color: var(--primary-light);
}

.round-button {
    border-radius: 1rem;
    padding: .7rem 1.1rem;
}

.button-link {
    text-decoration: none;
    color: black;
    font-weight: bold;
}

.button.button-link {
    color: white;
}

.button-link:hover {
    box-shadow: none;
    background-color: inherit;
    color: var(--secondary);
}

.button.button-link:hover {
    background-color: var(--secondary-light);
    cursor: pointer;
    color: white;
}

.link-button {
    text-decoration: underline;
    cursor: pointer;
}

.link-button:hover {
    color: var(--secondary);
}