table.table {
    border-collapse: collapse;
}

.table tr {
    border-bottom: 1px solid #ccc;
}

.table th,
.table td {
    text-align: left;
    padding: 4px;
}