.league-settings {
    padding: 1rem;
    display: flex;
}

.settings-nav {
    margin-right: 5rem;
}

.settings-nav li {
    margin-bottom: 2rem;
}

.settings-nav a {
    color: black;
    text-decoration: none;
}

.settings-nav a.active {
    border-bottom: 2px solid black;
    padding-bottom: 2px;
}

.settings-form {
    flex-grow: 1;
}