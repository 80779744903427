.event-group {
    padding: 0 !important;
}

.event-group-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
}

.event-group-header-unassigned {
    flex-grow: 1;
    text-align: center;
    margin: 1rem;
}

.event-group-footer {
    display: flex;
    padding: 1rem;
    margin-top: 1rem;
    border-top: 1px solid var(--border);
}

.event-group-footer .button {
    margin: 0 auto;
}

.event-group-info {
}

.event-group-label {
    padding: .5rem;
    text-align: center;
}

.event-group-info.group .event-group-label {
    border: 1px solid var(--primary);
    color: white;
    background-color: var(--primary);
}

.event-group-info.tables div {
    display: inline-block;
}

.table-numbers {
    width: 3rem;
    padding: .25rem;
}

.event-group-data {
    padding: .5rem;
    text-align: center;
}

.event-group-players {
    width: 100%;
    border-collapse: collapse;
}

.event-group-players-row {
    display: flex;
}

.event-group-players-row:hover {
    box-shadow: 0 1px 6px #e4e8eb;
    background-color: var(--highlight);
}

.event-group-players-cell {
    display: inline-block;
    padding: .5rem;
    margin: .5rem 1rem;
    line-height: 2rem;
}

.event-group-selector {
    text-align: right;
    flex-grow: 2;
}

.event-group-players-cell:last-child {
    flex-basis: fit-content;
    text-align: right;
}

