.register header {
    margin: 1rem 0;
}

.register .unauthenticated {
    margin: 1rem 0;
    padding: 1rem 0;
}

.register-caveat {
    border-top: 1px solid var(--inverse-border);
    width: 900px;
    max-width: 100%;
    margin: 5rem auto;
}

.register .iframe-wrapper {
    text-align: center;
}