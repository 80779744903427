/* Color Theme Swatches in Hex */
.big-moon-1-hex {
    color: #02070D;
}

.big-moon-2-hex {
    color: #193B59;
}

.big-moon-3-hex {
    color: #122A40;
}

.big-moon-4-hex {
    color: #3C5E73;
}

.big-moon-5-hex {
    color: #93B3BF;
}

/* Color Theme Swatches in RGBA */
.big-moon-1-rgba {
    color: rgba(2, 6, 12, 1);
}

.big-moon-2-rgba {
    color: rgba(24, 59, 89, 1);
}

.big-moon-3-rgba {
    color: rgba(17, 42, 63, 1);
}

.big-moon-4-rgba {
    color: rgba(59, 93, 114, 1);
}

.big-moon-5-rgba {
    color: rgba(147, 178, 191, 1);
}

/* Color Theme Swatches in HSLA */
.big-moon-1-hsla {
    color: hsla(213, 68%, 2%, 1);
}

.big-moon-2-hsla {
    color: hsla(208, 56%, 22%, 1);
}

.big-moon-3-hsla {
    color: hsla(208, 56%, 16%, 1);
}

.big-moon-4-hsla {
    color: hsla(202, 31%, 34%, 1);
}

.big-moon-5-hsla {
    color: hsla(196, 25%, 66%, 1);
}


/* Color Theme Swatches in Hex */
.secondary {
    color: var(--secondary);
}

.secondary-light {
    color: var(--secondary-light);
}

.primary {
    color: var(--primary);
}

.primary-dark {
    color: var(--primary-dark)
}

.primary-light {
    color: var(--primary-light);
}

/*!* Color Theme Swatches in RGBA *!*/
.secondary-rgba {
    color: rgba(242, 67, 82, 1);
}

.secondary-light-rgba {
    color: rgba(242, 121, 131, 1);
}

.primary-rgba {
    color: rgba(3, 119, 165, 1);
}

.primary-dark-rgba {
    color: rgba(1, 64, 89, 1);
}

.primary-light-rgba {
    color: rgba(3, 156, 191, 1);
}

/*!* Color Theme Swatches in HSLA *!*/
.secondary-hsla {
    color: hsla(354, 87%, 60%, 1);
}

.secondary-light-hsla {
    color: hsla(354, 82%, 71%, 1);
}

.primary-hsla {
    color: hsla(196, 96%, 33%, 1);
}

.primary-dark-hsla {
    color: hsla(196, 96%, 17%, 1);
}

.primary-light-hsla {
    color: hsla(191, 96%, 38%, 1);
}

.bg-foreground {
    background-color: var(--foreground);
}

.bg-primary-light {
    background-color: var(--primary-light);
}

.bg-secondary-light {
    background-color: var(--secondary-light);
}
