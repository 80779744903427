.reset-rating {
}

.reset-rating-body {
    text-align: center;
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.reset-rating-body label,
.reset-rating-body input {
    margin-bottom: 1rem;
}

.reset-rating-body input {
    width: 25%;
    min-width: 3rem;
    text-align: center;
    margin: 0 auto;
}