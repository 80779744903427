.create-league-event .fieldset input {
    min-width: 15rem;
}

.create-league-event .participants {
    display: flex;
}

.create-league-event .participant-section {
    flex: 1 0 calc(50% - 1rem);
}

.create-league-event .participating-player-list {
    border-right: 1px solid var(--border);
    /*padding-right: 1rem;*/
}

.create-league-event .available-player-list {
    /*padding-left: 1rem;*/
}

.fake-table {
    height: 50vh;
    overflow-y: scroll;
    border: 1px solid var(--border);
}

.cell:first-child {
    min-width: 10rem;
}

.cell {
    display: inline-block;
    /*padding: .5rem;*/
}

.row:hover {
    cursor: pointer;
    box-shadow: 0 1px 6px #e4e8eb;
}

.event-info {
    color: white;
}

.event-info .button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    box-shadow: 1px 2px 6px var(--inverse-shadow);
}

.event-info h3 {
    padding: 0;
    margin: .5rem 0;
}

.event-info label {
    color: white !important;
    font-weight: bold;
}

/*.event-info-body {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/

.event-info-row {
    display: flex;
    flex-direction: row;
}

.event-info-row fieldset {
    flex-grow: 1;
}

.event-group.add-group {
    /*background-color: var(--border);*/
    border: none !important;
    height: 10vh;
    text-align: center;
    line-height: calc(10vh - (.25rem * 2));
}

.event-group.add-group:hover {
    cursor: pointer;
}

.event-group.add-group:hover {
    background-color: var(--border);
    border-color: var(--inverse-border);
    color: var(--primary-dark);
    transition: 500ms ease;
}

.player-filter {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}

.player-filter .fieldset label,
.player-filter .fieldset input {
    display: inline-block;
    margin-left: 1rem;
}

.create-league-event h4 {
    font-weight: bold;
}

.create-league-event .work-pane .work-pane {
    margin: 2rem 0;
}

.unassigned p {
    text-align: center;
}

.create-league-event .work-pane h4 {
    margin-bottom: 2rem;
}