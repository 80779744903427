.league-events-body {
    margin: 2rem 0;
}

.review-results-table {
    border-collapse: collapse;
    border: 1px solid var(--border);
    width: 100%;
}

.review-results-table td,
.review-results-table th {
    border: 1px solid var(--border);
    text-align: center;
    padding: .5rem;
}

.result-cell-winner {
    background-color: var(--highlight);
}

.results-cell-name {
    font-size: 1.1rem;
    margin: .25rem 0;
}