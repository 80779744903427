.player-pane {
    border: 1px solid lightgrey;
    padding: 0;
    margin: 0 0 2rem;
}

.player-pane-header {
    background-color: lightgrey;
    margin: 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.player-pane-header-title {
    display: inline-flex;
    flex-grow: 1;
}

.player-pane-header h3 {
    padding: .5rem;
    font-size: 1.5rem;
    margin: 0;
}

.player-pane-header .edit,
.player-pane-header .minimize,
.player-pane-header .maximize,
.player-pane-header .unfocus {
    border: none;
    background: none;
    cursor: pointer;
}

.player-pane-header .edit {
    display: inline-block;
    height: 100%;
}

.player-pane-header .unfocus {
    font-size: 1.5rem;
    padding: 0 1rem;
}

.player-pane-header .unfocus:hover,
.player-pane-header .minimize:hover,
.player-pane-header .maximize:hover {
    color: white;
}

.player-pane-header .maximize {
    padding-left: 1rem;
}

.edit-player-form-container {
    display: flex;
    flex-direction: row;
}

.edit-player-form-fieldset {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: .5rem 1rem;
}

.edit-player-form-fieldset label {
    font-size: .75rem;
}

.edit-player-form-submit {
    font-size: 1rem;
    padding: 0 1rem;
    border: none;
    background: none;
    cursor: pointer;
}

.player-pane main {
    padding: 1rem 2rem;
}

.minimized {
    display: none;
}

.player-quick-stats dt,
.player-quick-stats dd {
    display: inline-block;
    margin-right: 1rem;
}

.player-quick-stats {
    background-color: lightgray;
    padding: .5rem;
    margin-bottom: 1rem;
}

.player-rating-history {
    min-height: 15rem;
    width: 100%;
    margin-bottom: 2rem;
}

nav.settings {
    position: relative;
    width: 100%;
    height: 1rem;
}

.settings-container {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
}

.settings-container.active {
    background-color: #eeeeee;
    filter: drop-shadow(0 15px 15px #333);
}

.settings-toggle-container {
    display: inline-flex;
    justify-content: flex-end;
    /*height: 1rem;*/
}

.settings-toggle {
    text-align: right;
    border: none;
    background: none;
    cursor: pointer;
    margin: .5rem;
    width: 1.5rem;
}

.settings-actions {
    /*background-color: #eeeeee;*/

    margin-right: 2rem;
}

.settings-actions,
.settings-actions li {
    width: 100%;
}

.settings-actions li {
    list-style-type: none;
    margin: .5rem 0;
}

.settings-actions button {
    border: none;
    background: none;
    cursor: pointer;
    width: 100%;
}

.player-match-history table {
    width: 100%;
    border: 1px solid lightgray;
}

.player-match-history th {
    text-align: left;
}

.player-match-history th:last-child {
    text-align: right;
}

.player-match-history td:last-child {
    text-align: right;
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen
and (min-device-width: 375px)
and (max-device-width: 812px)
and (-webkit-min-device-pixel-ratio: 3) {

    .player-pane {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 100vw;
        max-width: 100%;

        height: 100vh;
        max-height: 100%;

        background-color: white;

        z-index: 1010;
    }

    .player-match-history {
        overflow-x: scroll;
        max-height: 50vh;
    }
}

