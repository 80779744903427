.player-form {
    border: 1px solid lightgrey;
    padding: 0;
    margin: 0;
}

.player-form-header {
    background-color: lightgrey;
    margin: 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.player-form-header-title {
    display: inline-flex;
    flex-grow: 1;
}

.player-form-header h3 {
    padding: .5rem;
    font-size: 1.5rem;
    margin: 0;
}

.player-form-header .unfocus {
    border: none;
    background: none;
    cursor: pointer;
}

.player-form-header .unfocus {
    font-size: 1.5rem;
    padding: 0 1rem;
}

.player-form-header .unfocus:hover {
    color: white;
}

.form-container {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
}

.form-container .form-row {
    padding: 0 0 2rem 0;
}

.form-container input[type="text"],
.form-container input[type="number"] {
    padding: .5rem;
}

.form-container fieldset {
    display: inline-flex;
}

.form-container fieldset legend {
    font-size: 1.25rem;
}

.form-container fieldset .form-field-group {
    flex-grow: 1;
}

.form-field-group {
    display: flex;
    flex-direction: column;
}

fieldset.form-row > .form-field-group:first-of-type {
    margin-right: 1rem;
}

.player-form-submit {
    font-size: 1rem;
    height: 2.5rem;
    width: 66%;
    margin: 0 auto;
}