.sliding-panel-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 30em;
    max-width: 100%;
    transform: translateX(100%);
    transition: .3s ease-out;
}

.sliding-panel-wrapper.open {
    transform: translateX(0%);
}

.panel {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--inverse-background);
    color: var(--inverse-text);
    overflow: auto;
    padding: 0 1em;
}