.clickable-player-list {
    display: flex;
    flex-direction: column;
    /*padding: 0 2rem;*/
}

.clickable-player {
    margin: .25rem 0;
    display: inline-flex;
    flex-direction: row;
    background-color: inherit;
    border: 1px solid var(--inverse-foreground);
    padding: .5rem;
}

.clickable-player:hover {
    cursor: pointer;
}

.clickable-player:hover {
    background-color: var(--inverse-foreground);
    color: white;
    transition: all 100ms ease;
}

.clickable-player div {
    width: 33%;
    text-align: left;
}

.clickable-player div:last-child {
    text-align: right;
}