html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: "Whitney SSm A", "Whitney SSm B", "Helvetica Neue", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

address {
    font-style: normal;
    font-family: "Quicksand", "Rubik", sans-serif;
    font-weight: 400;
    line-height: 1.5rem;
}