legend {
    padding: 0;
    display: table;
}

fieldset {
    border: 0;
    padding: 0.01em 0 0 0;
    margin: 0;
    min-width: 0;
}

body:not(:-moz-handler-blocked) fieldset {
    display: table-cell;
}

dt, dd, dl {
    padding: 0;
    margin: 0;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    /* inherit font & color from ancestor */
    text-align: inherit;
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
}

/* Remove excess padding and border in Firefox 4+ */
button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

h1, h2, h3, h4 {
    padding: 0;
    margin: 0;
}