.app-title {
    color: var(--secondary) !important;
}

h1 {
    font-family: 'Catamaran', sans-serif;
    /*font-weight: 300;*/
    font-weight: 400;
}

h2, h3, h4 {
    font-family: "Quicksand", "Rubik", sans-serif;
    font-weight: 400;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-link,
.icon-link {
    color: black;
}

.header-link.text-link,
.header-link.icon-link {
    color: white;
}


.text-link:hover,
.icon-link:hover {
    color: var(--secondary);
    transition: 300ms ease color;
}
