.anonymous-access .explore-leagues .league-tile {
    min-height: 15rem;
}

.anonymous-access .page-header {
    min-height: 20rem;
    max-height: 50rem;
}

/*Small Mobile, <375px*/
@media only screen and (max-device-width: 767px) {
    .anonymous-access .page-header {
        height: 60vh;
        display: flex;
        flex-direction: column;
        background-image: url(../images/paddle-table-background@0.75x.png);
        background-repeat: no-repeat;
    }

    .anonymous-access .page-header .app-title {
        text-align: left;
    }

    .header-row {
        display: flex;
        flex-direction: row;
        margin-left: 1rem;
    }

    .page-header-nav .register {
        display: none;
    }

    .page-header-item-left,
    .page-header-item-right {
        flex-grow: 1;
    }

    .page-header-item-right {
        text-align: right;
        margin-top: 1rem;
        margin-right: 1rem;
    }

    .page-header-nav {
        width: 75%;
    }

    .round-button {
        border-radius: 0;
    }

    .header-row-app-description {
        margin: 2rem 0;
    }

    .app-description {
        margin-left: 1rem;
    }

    .app-description h2 {
        font-size: 1.3rem;
    }

    .explore-leagues {
        padding: 1rem 0;
        /*text-align: center;*/
        display: flex;
        flex-direction: column;
    }

    .explore-leagues h3,
    .features h3,
    .features p {
        margin-left: 1rem;
    }

    .feature {
        margin-top: 5rem;
    }

    .anonymous-access .league-tile-container {
        display: flex;
        flex-direction: column;
    }

    .anonymous-access .league-tile {
        margin-right: 1rem;
        /*height: 35vh;*/
    }

    .features {
        display: flex;
        flex-direction: column;
    }

    .feature-contents .image-container {
        width: 100%;
        overflow: hidden;
    }

    .feature-contents .image-container img {
        width: 100%;
    }

    .page-footer {
        background-color: var(--inverse-background);
        height: 20vh;
        min-height: 20rem;
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
    }

    .page-footer-section nav {
        margin: 3rem 0;
        text-align: center;
    }

    .page-footer-section nav li {
        margin: .75rem 1rem;
    }

    .page-footer-section nav a {
        color: white;
        text-decoration: none;
    }
}


/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 375px) {
    .anonymous-access .page-header {
        height: 50vh;
        background-size: 120%;
    }

    .app-description {
        width: 50%;
    }
}

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (-webkit-device-pixel-ratio: 3) {
    .anonymous-access .page-header {
        height: 45vh;
        background-size: 150%;
    }
}

/* Mobile Landscape */
@media only screen and (max-device-width: 767px) and (orientation: landscape) {
    .anonymous-access .page-header {
        height: 100vh;
        background-size: 150%;
    }
}

/* Table, Desktop ------*/
@media only screen and (min-device-width: 768px) {

    .anonymous-access {
    }

    .anonymous-access .page-header {
        height: 60vh;
        display: flex;
        flex-direction: column;
        background-image: url(../images/paddle-table-background@0.75x.png);
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .anonymous-access .app-title {
        font-size: 1.8rem;
    }

    .page-header-nav {
        padding: 1rem 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .round-button {
        border-radius: 1rem;
    }

    .page-header-nav .button-link {
        margin-left: 1rem;
    }

    .page-header-item-right {
        text-align: inherit;
        margin: inherit;
    }

    .page-header-nav .register {
        display: inherit;
        margin-right: 1rem;
    }

    .header-row-app-description {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .app-description {
        width: 50%;
        margin-left: 1rem;
    }

    .app-description h2 {
        font-size: 3rem;
    }

    .app-description p {
        font-size: 1.2rem;
        padding: 0;
        margin: 1rem .5rem;
    }

    .page-body {
    }

    .page-body-inner {
        padding: 1rem;
        width: 900px;
        max-width: 100%;
        margin: 0 auto;
    }

    .page-body-inner h3 {
        font-size: 1.5rem;
    }

    .explore-leagues {
        margin: 2rem 0;
    }

    .explore-leagues .league-tile-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 2rem;
        row-gap: 2rem;
        padding: 1rem 0;
    }

    .anonymous-access .explore-leagues .league-tile {
        margin-left: 1rem;
        /*height: 35vh;*/
    }

    .features {
        display: flex;
        flex-direction: row;
    }

    .features .feature {
        flex-grow: 1;
    }

    .feature-contents {
        width: 75%;
        font-weight: bold;
    }

    .feature-contents.club-owners {
        margin-left: auto;
        margin-right: 2rem;
    }

    .feature-contents h3 {
        text-align: left;
        font-size: 1.5rem;
    }

    .feature-contents .image-container {
        width: 100%;
        overflow: hidden;
    }

    .feature-contents .image-container img {
        width: 100%;
    }

    .page-footer {
        background-color: var(--inverse-background);
        height: 20vh;
        display: flex;
        flex-direction: row;
        padding-top: 3rem;
    }

    .page-footer-section {
        flex-grow: 1;
    }

    .page-footer-header .app-title-inverse {
        text-align: left;
        margin-left: 3rem;
    }

    .page-footer-section nav {
        text-align: right;
        margin-right: 3rem;
    }

    .page-footer-section nav li {
        margin: .5rem 0;
    }

    .page-footer-section nav a {
        color: white;
        text-decoration: none;
    }

}
