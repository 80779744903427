/*Small Mobile, <375px*/
@media only screen and (max-device-width: 767px) {
    .page-footer {
        background-color: var(--inverse-background);
        height: 20vh;
        min-height: 20rem;
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
    }

    .page-footer-section nav {
        margin: 3rem 0;
        text-align: center;
    }

    .page-footer-section nav li {
        margin: .75rem 1rem;
    }

    .page-footer-section nav a {
        color: white;
        text-decoration: none;
    }
}


/* Table, Desktop ------*/
@media only screen and (min-device-width: 768px) {
    .page-footer {
        background-color: var(--inverse-background);
        height: 20vh;
        display: flex;
        flex-direction: row;
        padding-top: 3rem;
    }

    .page-footer-section {
        flex-grow: 1;
    }

    .page-footer-header .app-title-inverse {
        text-align: left;
        margin-left: 3rem;
    }

    .page-footer-section nav {
        text-align: right;
        margin-right: 3rem;
    }

    .page-footer-section nav li {
        margin: .5rem 0;
    }

    .page-footer-section nav a {
        color: white;
        text-decoration: none;
    }
}
