.user-panel {
    padding: 1rem 0;
    overflow: hidden;
}

.user-panel-dismiss {
    color: white;
}

.user-panel-dismiss:hover {
    background-color: inherit;
    border: none;
    box-shadow: none;
    color: var(--secondary);
}

.user-panel-dismiss {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.user-panel-header {
    width: 100%;
    padding: 0 1rem;
    margin-bottom: 1rem;
}

.user-panel-header h2 {
    font-size: 2rem;
}

.user-panel-body {
    width: calc(100% - 1rem);
    padding: 1rem;

    display: flex;
    flex-direction: column;
}

.user-panel-action {
    padding: 1rem;
    line-height: 3rem;
    /*font-weight: bold;*/
    background-color: var(--inverse-foreground);
    text-transform: uppercase;
}

a.user-panel-action {
    color: var(--inverse-text);
    text-decoration: none;
}

.user-panel-action:hover {
    background-color: var(--inverse-highlight);
    cursor: pointer;
    transition: 300ms ease all;
}

.user-panel-footer {
    position: fixed;
    bottom: 0;
    padding: 1rem;
    display: flex;
    width: 100%;
}

.user-panel-footer .help {
    flex-grow: 1;
    margin-top: auto;
}

.help a {
    padding: 0;
}

.help a:hover {
    background-color: inherit;
    box-shadow: 0 0 0;
    color: var(--foreground-dark);
}

.user-panel-footer .links {
    margin-right: 4rem;
}

.user-panel-footer .links li {
    margin: .5rem 0;
}

.user-panel-footer .links a {
    color: white;
}

.user-panel-footer .links a:hover {
    color: var(--foreground-dark);
}
