.match-builder {
    border: 1px solid lightgrey;
    padding: 0;
    margin: 0 0 2rem;
}

.match-builder-header {
    background-color: lightgrey;
    margin: 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.match-builder-header-title {
    display: inline-flex;
    flex-grow: 1;
}

.match-builder-header h3 {
    padding: .5rem;
    font-size: 1.5rem;
    margin: 0;
}

.match-builder-header .unfocus {
    border: none;
    background: none;
    cursor: pointer;
}

.match-builder-header .unfocus {
    font-size: 1.5rem;
    padding: 0 1rem;
}

.match-builder-header .unfocus:hover {
    color: white;
}

.match-builder ul {
    display: flex;
    width: 100%;
}

.match-builder li {
    flex: 1 0 49%;
}

.match-builder-players-label {
    border: 1px solid lightgray;
    padding: 1rem 0;
    position: relative;
}

.match-builder-person-info,
.match-builder-remove {
    display: inline-block;
    vertical-align: middle;
}

.match-builder-won-loss {
    position: absolute;
    bottom: 0;
    right: .5rem;
}

.match-builder-name {
    font-size: 1.1rem;
}


.match-builder-name,
.match-builder-rating {
    margin: 0;
    padding: 0;
}

.match-builder-remove {
    position: absolute;
    top: 0;
    right: 0;
}

.remove {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1.2rem;
    line-height: 1.1rem;
}

.remove:hover {
    color: darkgray;
}

.match-builder .form-container fieldset legend {
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: 1rem;
}

.match-score {
    background-color: lightgrey;
    margin: .5rem 0;
}

.match-score-label {
    padding-top: 1rem;
    text-align: center;
}

.match-date {
    margin: 1rem 0;
}

.match-score-group {
    display: flex;
}

.match-score-input {
    flex: 1 0 49%;
}

.match-score-input input {
    width: 50%;
}

.match-score-input label,
.match-score-input input {
    margin: 0 auto;
}

.match-score-input {
    display: inline-flex;
    flex-direction: column;
}

.match-builder-submit {
    font-size: 1rem;
    height: 2.5rem;
    width: 66%;
    margin: 0 auto;
}