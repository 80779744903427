.work-pane {
    background-color: white;
    border-radius: .25rem;
    padding: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    /*border: 1px solid var(--primary-dark);*/
    box-shadow: 0 1px 6px #e4e8eb;
}

.work-pane.sticky {
    position: -webkit-sticky; /* Safari & IE */
    position: sticky;
    top: 1rem;
    background-color: var(--primary);
    border-radius: .25rem .25rem 0 0;
}

.work-pane-header {
    display: flex;
    margin: 1rem 0;
}

.work-pane-header h4 {
    flex-grow: 1;
    font-size: 1.2rem;
    border-left: 3px solid var(--primary);
    padding-left: .5rem
}

.work-pane .actions {
    margin: .5rem 0;
    display: flex;
    flex-direction: row-reverse;
}

.work-pane .work-pane {
    border: 1px solid black;
    border-radius: 0;
    box-shadow: 0 0 0;
}