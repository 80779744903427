.form .form-section {
    border-bottom: 1px solid var(--border);
    padding: 1rem 0;
    margin-bottom: 1rem;
}

.form-section h4 {
    text-transform: uppercase;
    border-left: 3px solid var(--primary);
    padding-left: .5rem;
}

.text-input {
    width: 100%;
    margin-bottom: 1rem;
}


.form-field-radio-group {

}

.form-field-group-empty {
    text-align: center;
    vertical-align: middle;
    color: grey;
    margin: 1rem 0;
}

.form-field-radio-group input {
    margin-right: .5rem;
    margin-left: 1rem;
}

.form-field-radio-group .selected {
    background-color: lightgrey;
}


.form-container {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
}

.form-container .form-row {
    padding: 0 0 2rem 0;
}

.form-container input[type="text"],
.form-container input[type="number"] {
    padding: .5rem;
}

.form-container fieldset {
    display: inline-flex;
}


.form-container fieldset .form-field-group {
    flex-grow: 1;
}

.form-field-group {
    display: flex;
    flex-direction: column;
}

fieldset.form-row > .form-field-group:first-of-type {
    margin-right: 1rem;
}