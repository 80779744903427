.modal-parent {
    -webkit-filter: blur(1px) grayscale(90%);
    -moz-filter: blur(1px) grayscale(90%);
    -o-filter: blur(1px) grayscale(90%);
    -ms-filter: blur(1px);
    filter: blur(1px) grayscale(90%);
}

.modal-overlay {
    z-index: 1000;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: #01223770;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 400px;
    max-width: 100%;

    height: 200px;
    max-height: 100%;

    background-color: white;

    z-index: 1010;
}

.modal-dismiss {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1.5rem;
    z-index: 1020;
}

.modal-dismiss:hover {
    color: darkgrey;
}

.modal-body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    /*padding: 20px 50px 20px 20px;*/

    overflow: auto;
}

.modal-body p {
    text-align: center;
    padding: 2rem;
    /*padding: 1rem 0;*/
}

.modal-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: darkslategray;
    padding: .5rem 0;

    text-align: center;
}

.modal-footer button {
    margin: 0 1rem;
}
