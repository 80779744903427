.contact {
    padding: 2rem 0;
}

.contact header {
    text-align: left !important;
    width: 900px !important;
    max-width: 90%;
    margin: 1rem;
    /*margin: 0 auto;*/
}

.contact-body {
    width: 900px;
    max-width: 90%;
    margin: 1rem;
    /*margin: 0 auto;*/
}

.contact-body div {
    margin: 2rem 0;
}