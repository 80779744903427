.result-table {
    border-collapse: collapse;
    /*border: 1px solid var(--border);*/
    width: 100%;
}

.league-events-results-legend {
    width: 100%;
    text-align: right;
    padding: 1rem 1rem;
}

.league-events-results-legend fieldset {
    display: inline-block;
    margin-left: .5rem;
}

.legend-skip {
    border-color: var(--foreground-dark);
}

.legend-skip label {
    background-color: var(--foreground-dark);
    border-color: var(--foreground-dark);
    color: var(--header-text) !important;
}

.legend-skip div {
    border-color: var(--foreground-dark);
}

.legend-won {
    border-color: var(--primary-light);
}

.legend-won label {
    background-color: var(--primary-light);
    border-color: var(--primary-light);
    color: var(--header-text) !important;
}

.legend-won div {
    border-color: var(--primary-light);
}

.legend-lost {
    border-color: var(--secondary-light);
}

.legend-lost label {
    background-color: var(--secondary-light);
    border-color: var(--secondary-light);
    color: var(--header-text) !important;
}

.legend-lost div {
    border-color: var(--secondary-light);
}

.result-table-row {
}

.result-table-row:hover {
    background-color: var(--highlight);
}

.result-table-name {
    width: 10rem;
    padding: .5rem .5rem;
}

.result-table-row td {
    border: 1px solid var(--border);
}

.result-table-null-cell {
    background-color: var(--border);
}

.result-table-result-cell {
    text-align: center;
    /*display: flex;*/
    padding: 0 !important;
    /*height: 4rem;*/
    height: 3rem;
}

.result-table-result-cell-container {
    width: 100%;
    height: 100%;
    display: flex;
    color: var(--header-text);
}

.result-table-result-cell-container input[type="radio"] {
    display: none;
}

.result-table-result-cell-skip,
.result-table-result-cell-win,
.result-table-result-cell-lose {
    flex: 1;
    height: 100%;
    line-height: 3rem;
    border: 1px solid var(--border);
}

.result-table-result-cell-skip {
    background-color: var(--foreground);
}

.result-table-result-cell-skip:hover {
    background-color: var(--foreground-dark);
    cursor: pointer;
}

.result-table-result-cell-skip.selected {
    background-color: var(--foreground-dark);
}

.result-table-result-cell-win {
    background-color: var(--foreground);
}

.result-table-result-cell-win:hover {
    background-color: var(--primary-light);
    cursor: pointer;
}

.result-table-result-cell-win.selected {
    background-color: var(--primary-light);
}

.result-table-result-cell-lose {
    background-color: var(--foreground);
}

.result-table-result-cell-lose:hover {
    background-color: var(--secondary-light);
    cursor: pointer;
}

.result-table-result-cell-lose.selected {
    background-color: var(--secondary-light);
}

