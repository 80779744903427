.player-pane-actions {
    display: flex;
    flex-direction: column;
}

.player-pane-show-button {
    width: 100%;
    text-align: center;
    margin: 1rem 0;
    color: var(--inverse-border);
    height: 3rem;
}

.player-pane-show-button:hover {
    cursor: pointer;
    background-color: var(--border);
}
