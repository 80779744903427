.League {
    padding: .5rem;
}

.League .snackbar .link-button {
    color: white;
}

.League-body {
    display: flex;
    flex-direction: column;
}

.League-body > section.column {
    flex-grow: 1;
}

section.actions nav {
    display: flex;
}

section.actions nav button {
    flex-grow: 1;
}

.player-table-column {
    overflow-y: scroll;
    max-height: 100vh;
}

.player-pane-column {
    padding-left: 1rem;
}

.action-column button {
    flex-grow: 1;
}

.RecordMatch button.remove {
    margin-left: 5rem;
}

/* ----------- Non-Retina Screens ----------- */
@media screen
and (min-device-width: 801px)
and (-webkit-min-device-pixel-ratio: 1) {
    .League {
        padding: 2rem;
    }

    .League-body {
        flex-direction: row;
    }

    .League-body > section.column {
        width: 50vw;
    }

    .column-contents {
        padding-right: 2rem;
    }
}
