:root {
    --secondary: #F24452;
    --secondary-light: #F27983;
    --primary: #0378A6;
    --primary-dark: #024059;
    --primary-light: #049DBF;
    --background: white;
    --foreground: #f1f2f4;
    --foreground-dark: #B1ACC2;
    --header-text: #121212;
    --underline: #7a170a;
    --border: #e4e8eb;
    --shadow: #b0b3b5;
    /*--border-dark: #e4e8eb;*/
    --text: black;
    --inverse-background: #122A40;
    --inverse-text: #93B3BF;
    --inverse-foreground: #193B59;
    --inverse-highlight: #22517a;
    --inverse-shadow: #02070D;
    --inverse-border: #3C5E73;
    --highlight: lightgoldenrodyellow;
}